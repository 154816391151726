import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 25px;
  .main{
    width: 100%;
    max-width: 1246px;
    margin: auto;
    .wrapper{
      display:flex;
      width:100%;
      justify-content:space-between;
      .button{
        width:100px;
        height:40px;
        background: #005c87;
        color:white;
        font-size:18px;
        cursor:pointer;
        border-radius:5px;
        align-items:center;
        justify-content:center;
        display:flex;
        margin:auto;
      }
    }
  }
`;

const CustomTextArea = styled.textarea`
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik-Medium";
  padding: ${({ padding }) => padding ? '0 15px' : '15px'};
  border-radius: 4px;
  border: 1px solid #005C874D;
  width: 500px;
  resize: none;
  color: #005C87;
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  height: ${({ height }) => height ? height : '500px'};
  background: #F7F5EB80;
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: #005C8799;
    opacity: 1;
    font-family: "Rubik";
  }

  :-ms-input-placeholder {
    color: #005C8799;
  }

  ::-ms-input-placeholder {
    color: #005C8799;
  }
`;

const CustomTextAreaV2 = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik-Medium";
  padding: ${({ padding }) => padding ? '0 15px' : '15px'};
  border-radius: 4px;
  border: 1px solid #005C874D;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  width: 500px;
  resize: none;
  color: #005C87;
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  height: ${({ height }) => height ? height : '500px'};
  background: #F7F5EB80;
  overflow:scroll;
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: #005C8799;
    opacity: 1;
    font-family: "Rubik";
  }

  :-ms-input-placeholder {
    color: #005C8799;
  }

  ::-ms-input-placeholder {
    color: #005C8799;
  }
`;

export { Container, CustomTextArea, CustomTextAreaV2 };