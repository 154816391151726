// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./bootstrap.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!./normalize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width:900px){#section1-grid{grid-template-columns:5rem 1fr 3rem;grid-template-rows:2fr 2fr;grid-template-areas:". image ." ". text ."}#section1-grid .text-sec{padding-top:0}#section1-grid .scroll-down-button-row{display:none}#section1-grid button{margin-left:auto;margin-right:auto;display:block}#section2-grid{grid-template-columns:5rem 1fr 3rem;grid-template-areas:". image ." ". text ."}#section3-grid{grid-template-columns:5rem 1fr 3rem;grid-template-areas:". image ." ". text .";padding-bottom:20px}}`, "",{"version":3,"sources":["webpack://./src/css/_media_queries.styl"],"names":[],"mappings":"AAAwB,yBACtB,eACE,mCAAuB,CACvB,0BAAoB,CACpB,0CAAqB,CACrB,yBACE,aAAa,CACf,uCACE,YAAS,CACX,sBACE,gBAAa,CACb,iBAAc,CACd,aAAS,CACb,eACE,mCAAuB,CACvB,0CAAqB,CACvB,eACE,mCAAuB,CACvB,0CAAqB,CACrB,mBAAgB,CAAA","sourcesContent":["@media (max-width: 900px)\n  #section1-grid\n    grid-template-columns: 5rem 1fr 3rem\n    grid-template-rows: 2fr 2fr\n    grid-template-areas: \". image .\" \". text .\"\n    .text-sec\n      padding-top: 0\n    .scroll-down-button-row\n      display: none\n    button\n      margin-left: auto\n      margin-right: auto\n      display: block\n  #section2-grid\n    grid-template-columns: 5rem 1fr 3rem\n    grid-template-areas: \". image .\" \". text .\"\n  #section3-grid\n    grid-template-columns: 5rem 1fr 3rem\n    grid-template-areas: \". image .\" \". text .\"\n    padding-bottom: 20px\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
