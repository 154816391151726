/* eslint-disable no-undef */
import React , {Component} from 'react';
import {Layout} from "../../components";
import PropTypes from "prop-types";
import {Container, CustomTextArea, CustomTextAreaV2} from './style';
const CryptoJS = require('crypto-js');
const secretKey = 'thisIsK3y'; // Replace with your secret key
const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV (or use a fixed one)

class NodeTest extends Component{

  constructor() {
    super();
    this.state = {
      data: "",
      data2: "",
      showEncryption: false,
      showDecryption: false,
    }
  }  


  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }; 

  // Encryption function
  encryptData = (data) =>{
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, { iv });
    return encrypted.toString();
  }

  // Decryption function
  decryptData = (encryptedData)=> {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey, { iv });
    const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return JSON.stringify(decrypted);
  }

  render() {
    const { data, showEncryption, showDecryption, data2 } = this.state;
    return (
      <Layout>
        <Container>
          <div className='main'>
            <div className='wrapper'>
              <CustomTextArea
                placeholder={"Enter Input Data"}
                name="data"
                value={data}
                onChange={this.onChangeInput}
                data-gramm_editor="false"
                type="text"
              />
              <div className='button' onClick={()=>{this.setState({showEncryption: true})}}>Encrypt</div>
              <CustomTextAreaV2>
                {data.length>0&& showEncryption? this.encryptData(data):null}
              </CustomTextAreaV2>
            </div>
          </div>
          <div style={{width:"100%", height:"1px", background:"#005c87", margin:"50px 0 50px 0"}}/>
          <div className='main'>
            <div className='wrapper'>
              <CustomTextArea
                placeholder={"Enter Input Data"}
                name="data2"
                value={data2}
                onChange={this.onChangeInput}
                data-gramm_editor="false"
                type="text"
              />
              <div className='button' onClick={()=>{this.setState({showDecryption: true})}}>Decrypt</div>
              <CustomTextAreaV2>
                {data2.length>0&& showDecryption?this.decryptData(data2):null}
              </CustomTextAreaV2>
            </div>
          </div>
        </Container>
      </Layout>
    );
  }
}

NodeTest.propTypes = {
  history: PropTypes.object
}
export default NodeTest;